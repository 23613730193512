import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import '../css/Sidebar.css';

function Sidebar({ setActiveContent, setSelectedFile, sidebarVisible, toggleSidebar }) {
  useEffect(() => {
    const sidebar = document.getElementById('sidebar');
    const iconTray = document.querySelector('.icon-tray');
    const closeButton = document.getElementById('close-sidebar');

    // if (sidebarVisible) {
    //   sidebar.classList.add('visible');
    //   sidebar.classList.remove('hidden');
    // } else {
    //   sidebar.classList.add('hidden');
    //   sidebar.classList.remove('visible');
    // }
    if (sidebarVisible) {
      sidebar.style.right = '8px';
    } else {
      sidebar.style.right = '-280px';
    }

    const handleCloseSidebar = () => {
      iconTray.classList.remove('hidden');
      iconTray.classList.add('visible');
      toggleSidebar(false);
    };
    // const handleCloseSidebar = () => {
    //   toggleSidebar(false);
    //   // sidebar.style.right = '-280px';
    //   iconTray.style.display = 'block';
    //   setTimeout(() => iconTray.classList.remove('hidden'), 10); // すぐに表示させてからスライドイン
    // };

    const handleOutsideClick = (event) => {
      if (event.target !== sidebar && !sidebar.contains(event.target) && sidebar.style.right === '0px') {
        handleCloseSidebar();
      }
    };

    closeButton.addEventListener('click', handleCloseSidebar);
    window.addEventListener('click', handleOutsideClick);

    const archiveLinks = document.querySelectorAll('.sidebar ul li a');
    archiveLinks.forEach(link => {
      link.addEventListener('click', (e) => {
        e.preventDefault();
        const file = link.getAttribute('data-file');
        setSelectedFile(file);
        setActiveContent('home');
        handleCloseSidebar();
      });
    });

    return () => {
      closeButton.removeEventListener('click', handleCloseSidebar);
      window.removeEventListener('click', handleOutsideClick);
      archiveLinks.forEach(link => {
        link.removeEventListener('click', (e) => {
          const file = link.getAttribute('data-file');
          setSelectedFile(file);
          setActiveContent('home');
          handleCloseSidebar();
        });
      });
    };
  }, [sidebarVisible, setActiveContent, setSelectedFile, toggleSidebar]);

  return (
    <div id="sidebar" className={`sidebar ${sidebarVisible ? 'visible' : 'hidden'}`}>
      <button id="close-sidebar" className="close-sidebar">
        <FontAwesomeIcon icon={faTimes} />
      </button>
      <h2>2024 г.</h2>
      <ul>
        <li><b><a href="#" data-file="/archive/202412.html">Последний</a></b></li>{/* 15.11変更 */}
        <li><a href="#" data-file="/archive/202411.html">Ноябрь</a></li>
        <li><a href="#" data-file="/archive/202410.html">Октябрь</a></li>
        <li><a href="#" data-file="/archive/202409.html">Сентябрь</a></li>
        <li><a href="#" data-file="/archive/202408.html">Август</a></li>
        <li><a href="#" data-file="/archive/202407.html">Июль</a></li>
        <li><a href="#" data-file="/archive/202406.html">Июнь</a></li>
      </ul>
    </div>
  );
}

export default Sidebar;
